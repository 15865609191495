<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <div class="box1">
      <div class="top">
        <div class="logo">
          <img style="height: 100%" src="../assets/logo.png" alt="" />
        </div>
        <div class="more">
          <img style="height: 100%" src="../assets/more.png" alt="" />
        </div>
      </div>
      <div class="text">
        <img
          style="height: 100%; width: 95%; margin-top: 54px"
          src="../assets/text.png"
          alt=""
        />
      </div>
      <!-- <div class="bottom">
        <img style="height: 100%; width: 100%" src="../assets/character.png" alt="" />
      </div> -->
    </div>
    <div class="box2">
      <div class="item1">
        <div class="title">为什么选择HELLO</div>
        <div class="main">
          <div class="img-box">
            <div class="img1">
              <img style="width: 100%" src="../assets/img1.png" alt="" />
            </div>
            <div class="img2">
              <img style="width: 102%" src="../assets/img2.png" alt="" />
            </div>
          </div>
          <div class="img-box">
            <div class="img3">
              <img style="width: 100%" src="../assets/img3.png" alt="" />
            </div>
            <div class="img4">
              <img style="width: 100%" src="../assets/img4.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="item2">
        <div class="title">客户案例</div>
        <div class="main">
          <div class="top-box">
            <div class="video-box">
             <img style="width: 100%; height: 100%" src="../assets/Video-Gif1.gif" alt="">
              <!-- <video controls muted style="width: 100%; height: 100%" autoplay="autoplay" loop>
                <source src="https://hello.yohelo.com/case/2023111701.mp4" />
              </video> -->
            </div>
            <div class="text">好书分享</div>
          </div>

          <div class="vertical">
            <div class="vertical-box1">
              <div class="video-box">
                <img style="width: 100%; height: 100%" src="../assets/Video-Gif2.gif" alt="">
                <!-- <video
                  style="width: 100%; height: 100%"
                  src="https://hello.yohelo.com/case/2023111702.mp4"
                  autoplay="autoplay"
                  controls
                  muted
                  loop
                ></video> -->
              </div>
              <div class="text">深夜美食诱惑</div>
            </div>
            <div class="vertical-box2">
              <div class="video-box">
                <img style="width: 100%; height: 100%" src="../assets/Video-Gif3.gif" alt="">
                <!-- <video
                  style="width: 100%; height: 100%"
                  src="https://hello.yohelo.com/case/2023111703.mp4"
                  autoplay="autoplay"
                  controls
                  muted
                  loop
                ></video> -->
              </div>
              <div class="text">奇幻平安夜</div>
            </div>
          </div>
        </div>
      </div>
      <div class="item3">
        <div class="title">全平台支持</div>
        <div class="list">
          <img style="width: 100%" src="../assets/list.png" alt="" />
        </div>
      </div>
      <div class="item4">
        <div class="text">湘ICP备2023009258号</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "HomeView",
  // components: {
  //   HelloWorld
  // }
};
</script>

<style lang="less">
.box1 {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/page3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top {
    .logo {
      height: 20px;
      float: left;
      margin-left: 20px;
      margin-top: 14px;
      img {
      }
    }
    .more {
      height: 10px;
      float: right;
      margin-right: 20px;
      margin-top: 14px;
      img {
      }
    }
  }
  .text {
    width: 100%;
    height: 133px;
    img {
    }
  }
  // .bottom {
  //   width: 100%;
  //   // height: 100%;
  //   img {
  //     position: relative;
  //     top: -133px;
  //   }
  // }
}
.box2 {
  width: 100vw;
  height: 100%;
  background-image: url("../assets/page2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .item1 {
    width: 100%;
    // height: 667px;
    padding-top: 50px;
    .title {
      height: 30px;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 0px 14px #8b5ef5;
      margin-bottom: 36px;
    }
    .main {
      width: 100%;
      .img-box {
        display: flex;
        margin-bottom: 20px;
        .img1 {
          width: 188px;
          height: 196px;
          margin-left: 17px;
        }
        .img2 {
          width: 150px;
          height: 196px;
        }
        .img3 {
          width: 150px;
          height: 196px;
          margin-left: 17px;
        }
        .img4 {
          width: 188px;
          height: 196px;
          margin-left: 4px;
        }
      }
    }
  }
  .item2 {
    width: 100%;
    height: 667px;
    padding-top: 30px;
    .title {
      height: 30px;
      font-size: 24px;
      // font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      // font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 0px 14px #8b5ef5;
      margin-bottom: 40px;
    }
    .main {
      .top-box {
        width: 322px;
        height: 218px;
        margin-left: 24px;
        background-image: url("../assets/horizontal.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 5px;
        .video-box {
          width: 100%;
          height: 176px;
        }
        .text {
          margin-top: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          color: #ffffff;
        }
      }
    }
    .vertical {
      margin-top: 24px;
      display: flex;
      margin-left: 24px;
      .vertical-box1 {
        width: 154px;
        height: 294px;
        background-image: url("../assets/vertical.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .video-box {
          margin-top: 5px;
          width: 100%;
          height: 258px;
        }
        .text {
          margin-top: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          color: #ffffff;
        }
      }
      .vertical-box2 {
        width: 154px;
        height: 294px;
        background-image: url("../assets/vertical.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: 22px;
        .video-box {
          margin-top: 5px;
          width: 100%;
          height: 258px;
        }
        .text {
          margin-top: 10px;
          font-size: 12px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          color: #ffffff;
        }
      }
    }
  }
  .item3 {
    width: 100%;
    .title {
      height: 30px;
      font-size: 24px;
      // font-family: Microsoft YaHei, Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      text-shadow: 0px 0px 14px #8b5ef5;
      margin-bottom: 13px;
    }
    .list {
      width: 100%;
    }
  }
  .item4 {
    width: 100%;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      font-size: 10px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}

.video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

/* 隐藏video 播放按钮 */
.video::-webkit-media-controls-play-button {
  display: none !important;
}

/* 隐藏video 进度条 */
.video::-webkit-media-controls-timeline {
  display: none !important;
}

/* 隐藏video 观看的当前时间 */
.video::-webkit-media-controls-current-time-display {
  display: none !important;
}

/* 隐藏video 剩余时间 */
.video::-webkit-media-controls-time-remaining-display {
  display: none !important;
}

.video::-webkit-media-controls-toggle-closed-captions-button {
  display: none !important;
}

/* 隐藏video 所有控件 */
.video::-webkit-media-controls-enclosure {
  display: none !important;
}

video::-webkit-media-controls {
  display: none !important;
}
</style>
